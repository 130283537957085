import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { api } from '../Constants';
import ReCAPTCHA from "react-google-recaptcha";

function Contactus() {
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    const [name, setName] = useState("");

    const [mobile, setMobile] = useState("");

    const [email, setEmail] = useState("");

    const [city, setCity] = useState("");

    const [message, setMessage] = useState("");

    var subject = "testing";

    let nameerror = /^[a-zA-Z]{3,30}$/.test(name);
    let numbererror = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(mobile);
    let emailrerror = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

    const handleClick = async () => {

        if (name === "") {
            document.getElementById("nameerror").innerHTML = 'Name is required'
        }
        else if (!nameerror) {
            document.getElementById("nameerror").innerHTML = 'Enter a valid name'

        }
        else {
            document.getElementById("nameerror").innerHTML = ''
        }

        //number valid
        if (mobile === "") {
            document.getElementById("numbererror").innerHTML = 'Number is required'
        }
        else if (!numbererror) {
            document.getElementById("numbererror").innerHTML = 'Enter a valid number'
        }
        else {
            document.getElementById("numbererror").innerHTML = ''
        }
        //email valid errormessage
        if (email === "") {
            document.getElementById("emailerror").innerHTML = 'Email is required'
        }
        else if (!emailrerror) {
            document.getElementById("emailerror").innerHTML = 'Enter a valid email'
        }
        else {
            document.getElementById("emailerror").innerHTML = ''
        }
        //message valid
        // city error
        if (city === "") {
            document.getElementById("cityerror").innerHTML = 'City is required'
        }
        else {
            document.getElementById("cityerror").innerHTML = '';
        }
        // city error
        if (message === "") {
            document.getElementById("errormessage").innerHTML = 'Message is required'
        }
        else {
            document.getElementById("errormessage").innerHTML = ""
        }
        if (nameerror && numbererror && emailrerror && message) {
            const dataTosubmit = {
                name,
                email,
                mobile,
                message,
                city,
                subject,
            };
            fetch(api + "add_enquirydetails", {
                method: "POST",
                headers: {
                    Accept: "aplication/json",
                    "Content-Type": "application/json;charset=UTF-8",

                },
                body: JSON.stringify(dataTosubmit),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.status === 200) {
                        setName("")
                        setMobile("")
                        setEmail("")
                        setMessage("")
                    }
                    else {
                        alert("Some error occured");
                    }
                });
        }


    }
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    return (
        <div>
            <div>
                <Helmet>
                    Contact Us || Sri Vishali Art Bangles
                </Helmet>
            </div>
            <div className="contact-area section-padding ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-message">
                                <h4 className="contact-title">To Become a Partner</h4>
                                <form action='javascript:void(0);' className="contact-form">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 ">
                                            <input type="text"
                                                name="name" id="name"
                                                placeholder='Your Name'
                                                onChange={(event) => setName(event.target.value)}
                                                autocomplete="off"
                                                required=""
                                                onKeyPress={(event) => {
                                                    if (!/[a-z,A-Z]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                            <small id='nameerror'></small>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <input name="mobile"
                                                placeholder='Your Phone No'
                                                maxlength="10" id="mobile"
                                                autocomplete="off"
                                                onChange={(event) => setMobile(event.target.value)}
                                                rquired=""
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                            <small id='numbererror'></small>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <input type="text"
                                                placeholder='Your e-mail'
                                                name="email"
                                                id="email"
                                                autocomplete="off"
                                                required=""
                                                onChange={(event) => setEmail(event.target.value)} />
                                            <small id='emailerror'></small>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <input type="text"
                                                placeholder='City'
                                                name="city"
                                                id="city"
                                                autocomplete="off"
                                                required=""
                                                onChange={(event) => setCity(event.target.value)} />
                                            <small id='cityerror'></small>
                                        </div>

                                        <div className="col-12">
                                            <div className="contact2-textarea">
                                                <textarea type="text" name="message"
                                                    placeholder='Your message'
                                                    id="message"
                                                    autocomplete="off"
                                                    required=""
                                                    onChange={(event) => setMessage(event.target.value)}></textarea>
                                                <small id='errormessage'></small>
                                            </div>

                                            <div className="col-12">
                                                <ReCAPTCHA
                                                    sitekey={"6LdGiY0oAAAAAH9EQi94D43RkIXkRWpc1x99AUTv"}
                                                    Secret Key={"6LdGiY0oAAAAALltd2vMsg0i0HeJ9J33rYDM7pnP"}
                                                    className="g-recaptcha my-3"
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="contact-btn">
                                                <button className="btn btn-sqr" onClick={handleClick}>Send Message</button>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <p className="form-messege"></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-info">
                                <h4 className="contact-title">Contact Us</h4>
                                <ul>
                                    <li><i className="fa fa-fax"></i> <a href='https://www.google.com/maps/place/Sri+Vishali+Art+Bangles/@11.0059286,76.9575313,17z/data=!4m14!1m7!3m6!1s0x3ba8598d1499ebd3:0x3bd9ab36699ab9e5!2sSri+Vishali+Art+Bangles!8m2!3d11.0059286!4d76.9575313!16s%2Fg%2F11fkt4p67f!3m5!1s0x3ba8598d1499ebd3:0x3bd9ab36699ab9e5!8m2!3d11.0059286!4d76.9575313!16s%2Fg%2F11fkt4p67f?hl=en-US&entry=ttu'> Door no: 7/8, Devangapet street - 3, Near flower Market, Coimbatore - 641001. </a> </li>
                                    <li><i className="fa fa-envelope-o"></i> <a href='mailto:orders@arthikajewels.com'> orders@srivishaliartbangles.com </a></li>
                                    <li><i className="fa fa-phone"></i> <a href="tel: +91 9677711148">+91 9677711148 </a> , <a href="tel: +91 90003720691"> +91 90003720691</a> </li>
                                    <li><i className="fa fa-phone"></i> <a href="tel: +91 9677748111"> +91 9677748111</a> , <a href="tel: +91 9489631148"> +91 9489631148</a> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="map-area section-padding">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 col-sm-12 col-md-12'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.4353255567357!2d76.9575313!3d11.005928599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8598d1499ebd3%3A0x3bd9ab36699ab9e5!2sSri%20Vishali%20Art%20Bangles!5e0!3m2!1sen!2sin!4v1697029743223!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Contactus;
