import React from 'react'
import footerlogo from '../../assets/new-images/logo.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { api } from '../Constants';
function Footer() {
    const [categorylist, setCategorylist] = useState([]);
    useEffect(() => {
        axios.post(api + "category_list")
            .then((response) => {
                setCategorylist(response.data.data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);
    return (
        <div>
            <footer class="footer-widget-area">
                <div class="footer-top section-padding">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="widget-item">
                                    <div class="widget-title">
                                        <div class="widget-logo">
                                            <Link to="/">
                                                <img src={footerlogo} alt="brand logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="widget-body">
                                        <p><b>Sri Vishali Art Bangles</b> is one of the best Jewellery Designer in Coimbatore. With over three decades of experience, we are committed to provide the best ever products to our clients.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6">
                                <div class="widget-item">
                                    <h6 class="widget-title">Quick Links</h6>
                                    <div class="widget-body">
                                        <address class="contact-block">
                                            <ul>
                                                <li> <Link to="/">Home</Link></li>
                                                <li><Link to="/About-Us">About Us</Link></li>
                                                <li> <Link to="/contact-us">Contact Us</Link></li>
                                            </ul>
                                        </address>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="widget-item">
                                    <h6 class="widget-title">Popular Collections</h6>
                                    <div class="widget-body">
                                        <ul class="info-list">
                                            {categorylist !== undefined && categorylist.length > 0 ? categorylist.slice(0, 5).map((ca) => {
                                                return (
                                                    <li key={ca.category_id}> <Link to={`/category/${ca.category_slug}`}>{ca.name}</Link></li>
                                                )
                                            }) : null}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="widget-item">
                                    <h6 class="widget-title">Contact Us</h6>
                                    <address class="contact-block">
                                        <ul>
                                            <li><i class="pe-7s-home"></i>  <a href="https://www.google.com/maps/place/Sri+Vishali+Art+Bangles/@11.0059339,76.9549564,17z/data=!3m1!4b1!4m6!3m5!1s0x3ba8598d1499ebd3:0x3bd9ab36699ab9e5!8m2!3d11.0059286!4d76.9575313!16s%2Fg%2F11fkt4p67f?hl=en-US&entry=ttu" target="_blank"> Door no: 7/8, Devangapet street - 3, Near flower Market, Coimbatore - 641001.</a>  </li>
                                            <li><i class="pe-7s-mail"></i> <a href="mailto:orders@arthikajewels.com">orders@srivishaliartbangles.com</a></li>
                                            <li><i class="pe-7s-call"></i> <a href="tel: +91 9677711148"> +91 9677711148</a>, <a href="tel: +91 9003720691"> +91 9003720691</a></li>
                                            <li><i class="pe-7s-call"></i> <a href="tel: +91 9677748111"> +91 9677748111</a>, <a href="tel: +91 9489631148"> +91 9489631148</a></li>
                                        </ul>
                                    </address>
                                    <div class="widget-body social-link">
                                        <a href="https://www.facebook.com/arthikajewel" target='_blank'><i class="fa fa-facebook"></i></a>
                                        <a href="https://www.instagram.com/arthikajewels/" target='_blank'><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="copyright-text text-left">
                                    <p>Copyrights © 2023   by <Link to="/"><b> Sri Vishali Art Bangles</b></Link> all rights reserved.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="copyright-text footer-end">
                                    <p>Developed by   <a href="https://www.banyaninfotech.com/"><b>The Banyan Infotech.</b></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
