
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { useParams, useLocation, Link } from 'react-router-dom';
import { api } from '../Constants';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
import Customarrows from '../Homepage/Customarrows';
function Productdetail() {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => { onTop() }, [routePath]);


    var subject = "testing";

    const params = useParams();
    var prd_slugname = params.slug_name;


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = (productCode) => {
        setShow(true);
        setFormData({ ...formData, product_code: productCode });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };
    const handleChange3 = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };
    const handleChange4 = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        city: '',
        message: '',
        subject,
        // Add other form fields as needed
    });
    const handleClick = () => {
        if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {
            fetch(api + 'add_enquirydetails', {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    changeOrigin: true,
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (response.status === 200) {
                        alert("send message succesfully");
                        handleClose();
                    }
                    else (
                        alert("some error is occured")
                    )
                })
        }
    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <Customarrows className="slick-prev" isNext={false} />,
        nextArrow: <Customarrows className="slick-next" isNext={true} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [data, setData] = useState([]);
    useEffect(() => {
        axios.post(api + 'search_slugproduct', { slug: prd_slugname })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    // console.log(error.response)
                } else if (error.request) {
                    // console.log(error.request)
                } else {
                    // console.log("error")
                }
            });
    }, [params]);

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        <div><div>
            <Helmet>
                <title>Product Details</title>
            </Helmet>
        </div>
            {data !== undefined && data.length > 0 ? data.map((item) => {
                return (
                    <div>
                        <div class="shop-main-wrapper section-padding pb-0">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 order-1 order-lg-2">
                                        <div class="product-details-inner">
                                            <div class="row">
                                                <div className='d-flex'>
                                                    <div class="col-lg-5">
                                                        <div class="product-large-slider">

                                                            <div class="pro-large-img" >
                                                                <img src={item.product_image} alt="product-details" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-7">
                                                        <div class="product-details-des mx-5">
                                                            <h3 class="product-name">{item.name}</h3>
                                                            <table>
                                                                <tr>
                                                                    <td colspan="1"> <p class="manufacturer-name prod-detcode-color">Product Code</p></td><td>:</td><td><p class="manufacturer-name prod-detcode-color">  <span> {item.product_code}</span></p></td>
                                                                </tr>
                                                                <tr><td colspan="1"> <p class="manufacturer-name prod-detcode-color">Quantity </p></td><td>:</td><td> <p class="manufacturer-name prod-detcode-color">  <span> {item.quantity} </span> </p></td></tr>
                                                                <tr><td colspan="1"> <p class="manufacturer-name prod-detcode-color">Collections </p></td><td>:</td><td> <p class="manufacturer-name prod-detcode-color">   <Link to={`/category/${item.category_slug_name}`}>{item.category_name}</Link></p></td></tr>
                                                                <tr><td colspan="1"> {item.customized ? (
                                                                    <p className="manufacturer-name prod-detcode-color d-flex">
                                                                        Customization
                                                                    </p>) : null}</td><td> {item.customized ? (<div>:</div>) : null}</td><td>
                                                                        {item.customized ? (
                                                                            <p className="manufacturer-name prod-detcode-color d-flex">
                                                                                <span className='mx-1' dangerouslySetInnerHTML={{ __html: item.customized }}></span>
                                                                            </p>
                                                                        ) : null}</td></tr>
                                                                <tr><td colspan="1">  {item.description ? (
                                                                    <p class="pro-desc d-flex prod-detcode-color" >Description  </p>
                                                                ) : null}</td><td> {item.description ? (
                                                                    <div>:</div>
                                                                ) : null}</td><td>  {item.description ? (
                                                                    <p class="pro-desc d-flex prod-detcode-color" >  <span dangerouslySetInnerHTML={{ __html: item.description }}></span></p>
                                                                ) : null}</td></tr>
                                                            </table>
                                                            <div class="quantity-cart-box d-flex align-items-center">
                                                                <div class="action_link">
                                                                    <a class="btn btn-cart2" href="javascript:void(0);" onClick={handleShow}>Enquiry Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section class="related-products section-padding">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="section-title text-center">
                                            <h2 class="title">Related Designs</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="product-carousel-4 ">
                                            <Slider {...settings}>
                                                {item.related_product !== undefined && item.related_product.length > 0 ? item.related_product.map((st) => {
                                                    return (
                                                        <div class="product-item">
                                                            <figure class="product-thumb">
                                                                <Link to={`/product-details/${st.prd_slug_name}`}>
                                                                    <img class="pri-img" src={st.product_image} alt="product" />
                                                                </Link>
                                                                <div class="cart-hover">
                                                                    <button class="btn btn-cart" onClick={() => {
                                                                        handleShow(item.product_code)
                                                                    }}>Enquiry Now</button>
                                                                </div>
                                                            </figure>
                                                            <div class="product-caption text-center">
                                                                <h6 class="product-name">
                                                                    <Link to={`/product-details/${st.prd_slug_name}`}>{st.name}</Link>
                                                                </h6>
                                                                <div class="product-identity">
                                                                    <p class="manufacturer-name">Product Code : <span className='price-regular'> {st.product_code} </span></p>
                                                                </div>

                                                                <div class="price-box">
                                                                    <span class="price-old">Weight :</span>
                                                                    <span class="price-regular">{st.weight} g</span>
                                                                </div>
                                                                <div class="price-box">
                                                                    <span class=" price-old">Qty : </span>
                                                                    <span class="price-regular">{st.quantity} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : null}

                                            </Slider>

                                            <Modal show={show} onHide={handleClose} className='modal fade entry-popup'>

                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"> <span>For Enquiry Enter Your Details</span></h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ border: 'none' }} onClick={() => {
                                                        setShow(false)
                                                    }}>
                                                        <span className='contactclosebutton' aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <Modal.Body>
                                                    <form method="POST" action='javascript:void(0)'>
                                                        <div className="row mb3 g-3">
                                                            <div className="col-lg-12 col-md-12 mb20">
                                                                <input type="text" className="form-control" placeholder="Your Name"
                                                                    id='name'
                                                                    name="name"
                                                                    value={formData.name}
                                                                    onChange={handleChange}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[a-z,A-Z]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    required

                                                                ></input>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 mb20">
                                                                <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                                                                    name="mobile"
                                                                    value={formData.mobile}
                                                                    onChange={handleChange1}
                                                                    maxLength="10"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    required
                                                                ></input>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 mb20">
                                                                <input type="email" className="form-control" placeholder="Email Address" id="email"
                                                                    name="email"
                                                                    value={formData.email}
                                                                    onChange={handleChange2}
                                                                    required
                                                                ></input>
                                                            </div>

                                                            <div className="col-lg-12 col-md-12 mb20">
                                                                <input type="text" className="form-control" placeholder="Enter You City" id="city"
                                                                    name="city"
                                                                    value={formData.city}
                                                                    onChange={handleChange4}
                                                                    required
                                                                ></input>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                                                                name="message"
                                                                value={formData.message}
                                                                onChange={handleChange3}
                                                                required
                                                            ></textarea><small id="messageerror" class="error"></small></div>
                                                            <div>
                                                                <div className="col-lg-12 col-md-12 mb20">
                                                                    <ReCAPTCHA
                                                                        sitekey={"6LdGiY0oAAAAAH9EQi94D43RkIXkRWpc1x99AUTv"}
                                                                        Secret Key={"6LdGiY0oAAAAALltd2vMsg0i0HeJ9J33rYDM7pnP"}
                                                                        className="g-recaptcha my-3"
                                                                        onChange={onChange}
                                                                    />
                                                                </div>
                                                                <Modal.Footer className='validationpopup-button-sec'>
                                                                    <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                                                                    <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                                                                        setShow(false)
                                                                    }}>close</button>
                                                                </Modal.Footer></div>
                                                        </div>
                                                    </form>
                                                </Modal.Body>

                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }) : null}
        </div>
    )
}

export default Productdetail


