import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/css/plugins/animate.css';
import './assets/css/plugins/jqueryui.min.css';
import './assets/css/plugins/nice-select.css';
import './assets/css/plugins/slick.min.css';
import './assets/css/vendor/bootstrap.min.css'; //warning
import './assets/css/vendor/font-awesome.min.css';
import './assets/css/vendor/pe-icon-7-stroke.css';
import './assets/css/responsive.css';
import './assets/css/style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);