import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Homeparent from './components/Homepage/Homeparent';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Aboutmain from './components/About/About-main';
import Contactus from './components/Contact-us/Contactus';
import Breadcrump from './components/Breadcrump/Breadcrump';
import ScrollToTop from 'react-scroll-to-top';
import Productdetail from './components/Productdetail/Productdetail'
import Category from '../src/components/Category/Category'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div>
      <BrowserRouter basename='/'> 
        <Header />
        <ScrollToTop smooth component={<FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>} className="scroll icon" />
        <Routes>
          <Route path="/" element={<div><Homeparent /></div>}></Route>
          <Route path="/About-Us" element={<div><Breadcrump cont2="About Us" /><Aboutmain /></div>}></Route>
          <Route path="/contact-us" element={<div><Breadcrump cont2="Contact Us" /><Contactus /></div>}></Route>
          <Route path="/product-details/:slug_name" element={<div><Breadcrump cont2="Product Detail" /><Productdetail /></div>}></Route>
          <Route path="/category/:slug" element={<div><Breadcrump cont2="Collection" /><Category /></div>}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;